import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Apple Keynote"
    img_name="keynote_logo.png"
    link="https://www.apple.com/keynote/"
    description="Apple Keynote is still the best tool on MacOS for designing compelling presentations."
    twitterHandleTool="Keynote"
  />
)

export default Tool;


